import { useState, useEffect } from "react";
import styles from "./WordsAnimation.module.css";

const WordsAnimation = ({words = []}) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [animationClass, setAnimationClass] = useState(styles.circularSlideInUp);

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationClass(styles.circularSlideOutUp); 

            setTimeout(() => {
                setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length); 
                setAnimationClass(styles.circularSlideInUp); 
            }, 500); 
        }, 1500); 

        return () => clearInterval(interval);
    }, [currentWordIndex, words]);

    return (
        <div className={styles.wordsContainer}>
            <h2 className={`${styles.wordsBox} ${animationClass}`}>
                {words[currentWordIndex]}
            </h2>
        </div>
    )
}

export default WordsAnimation;