import "./users.css";
import Lottie from "react-lottie";
import { toast } from "react-toastify";
import { Paper } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import socket from "socket";
import DialogForm from "@components/DialogForm";
import { getFormJson } from "@utils/formUtils";
import { isSuperAdmin } from "@utils/accessControl";
import ConfirmDialog from "@components/ConfirmDialog";
import { generatePassword } from "@utils/securityUtils";
import useResponsiveView from "@hooks/useResponsiveView";
import useResourceMutation from "@hooks/useResourceMutation";
import { FillButton, CustomGrid } from "@components";
import deleteAnimation from "@animations/deleteAnimation.json";
import { PlusIcon, ResetPasswordIcon } from "@icons/ActionsIcons";
import { addUser, deleteUser, fetchUsers, updateUser } from "@services/usersService";
import { ADD_USER_FORM, EDIT_USER_FORM, RESET_USER_PASSWORD_FORM } from "@constants/forms";
import { AccessRenderer, ActionsRenderer, NameRenderer, PermissionsRenderer, StatusRenderer } from "@renderers/users-renderers";
import { usersDialogTypes as uDialogTypes } from "@constants/dialogsEnums";
import { useAuth } from "@contexts/AuthContext";
const Users = () => {
    const queryClient = useQueryClient();
    const queryKeys = useMemo(()=>['users'], []);
    const { data:users=[] } = useQuery({
        queryKey : queryKeys,
        queryFn : fetchUsers,
        staleTime : Infinity
    })
    
    const { 
        mutateAsync:mutateAddUser, 
    } = useResourceMutation(queryKeys, 'add', addUser)
    
    const { 
        mutateAsync:mutateDeleteUser, 
    } = useResourceMutation(queryKeys, 'delete', deleteUser)
    
    const { 
        mutateAsync:mutateUpdateUser, 
    } = useResourceMutation(queryKeys, 'update', updateUser)

    const isMobile = useResponsiveView();
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(uDialogTypes.NONE);
    const [addUserErrors, setAddUserErrors] = useState({});
    const { user } = useAuth();
    const USER_LIMIT_REACHED = users?.length >= user?.plan.max_users;
    const generatedPassword = useMemo(generatePassword, [openDialog]);
    
    const [columnDefs, ] = useState([
        { headerName: 'Name', 
            sortable: true, 
            filter: true, 
            cellRenderer:NameRenderer,
            filterValueGetter : ({data:user})=>user.firstname + ' ' + user.lastname + ' ' + user.username,
            comparator : (valueA, valueB, {data:user1}, {data:user2}, isDescending)=>{
                const stringA = `${user1.firstname} ${user1.lastname}`;
                const stringB = `${user2.firstname} ${user2.lastname}`;
                return stringA.localeCompare(stringB);
            }
        },
        { field:'role', headerName: 'Role', sortable: true, filter: true, cellClass:"user-role" },
        { field:'verified', headerName: 'Status', sortable: true, filter: true, cellRenderer:StatusRenderer},
        { headerName: 'Access', field:'verified', cellRenderer:AccessRenderer, cellClass:"centered-cell",
            cellRendererParams:{
                onUpdate:(user_id, update)=>mutateUpdateUser({data:[user_id, update]})
            }
         },
        { headerName: 'Permissions', cellRenderer:PermissionsRenderer, cellClass:"centered-cell",
            cellRendererParams:{
                onUpdate:(user_id, update)=>mutateUpdateUser({data:[user_id, update]})
            }
         },
        { headerName: 'Actions', cellRenderer:ActionsRenderer, cellClass:"centered-cell",  
            cellRendererParams:{
                onDelete:(user_id)=>handleAction(user_id, 'delete'), 
                onEdit:(user_id)=>handleAction(user_id, 'edit')
            }
        }
    ])

    const handleAddUser = async (event)=>{
        event.preventDefault();
        const formJson = getFormJson(event);
        const usersData = queryClient.getQueryData(queryKeys);
        if(usersData.some(user=>user.username === formJson.email)){
            setAddUserErrors({email:`Email ${formJson.email} already registered!`});
            return;
        }
        try {
            await mutateAddUser({data:formJson});
            setOpenDialog(uDialogTypes.NONE);
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }finally {
            setOpenDialog(uDialogTypes.NONE);
        }
    }

    const handleEditUser = async (event) => {
        event.preventDefault();
        const formJson = getFormJson(event);
        const diff = Object.keys(formJson).filter(key => 
            formJson[key] !== selectedUser[key] && !(formJson[key] == null && selectedUser[key] == null)
        );
        const update = Object.fromEntries(Object.entries(formJson).filter(([key, value])=>diff.includes(key)))
        
        try {
            await mutateUpdateUser({data:[selectedUser._id, update]});
            setOpenDialog(uDialogTypes.NONE);
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }finally {
            setOpenDialog(uDialogTypes.NONE);
        }
    }

    const handleDeleteUser = async () => {
        try{
            await mutateDeleteUser({data:selectedUser._id});
            setOpenDialog(uDialogTypes.NONE);
            toast.success("Delete User successfully", {closeOnClick:true});
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }finally {
            setOpenDialog(uDialogTypes.NONE);
        }
    }

    const handleResetPassword = async (event)=>{
        event.preventDefault();
        const formJson = getFormJson(event);
        try{
            const usersData = queryClient.getQueryData(queryKeys);
            await mutateUpdateUser({data:[usersData.find(user=>user.username === formJson.user)?._id, {password:formJson.password}]});
            toast.success("Password reset successfully", {closeOnClick:true});
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }finally {
            setOpenDialog(uDialogTypes.NONE);
        }
    }

    const handleAction = (user_id, type)=>{
        const usersData = queryClient.getQueryData(queryKeys);
        setSelectedUser(usersData.find(user=>user._id === user_id));
        switch(type){
            case 'delete':
                setOpenDialog(uDialogTypes.DELETE_USER);
                break;
            case 'edit' :
                setOpenDialog(uDialogTypes.EDIT_USER);
                break;
            default:
                console.log("Unrecognized type : ", type);
        }
    }

    useEffect(()=>{
        const handleSocketUpdate = async ({type, payload}) =>{
            try{
                switch(type){
                    case 'add':
                        await mutateAddUser({data:payload, cacheOnly:true});
                        break;
                    case 'update':
                        await mutateUpdateUser({data:payload, cacheOnly:true});
                        break;
                    case 'delete':
                        await mutateDeleteUser({data:payload, cacheOnly:true});
                        break;
                    default:
                        console.log(`Unrecognized type received for update-users event : ${type}`);
                }
            }catch(error){
                console.log(error);
            }
        }

        socket.on("update-users", handleSocketUpdate);
        return ()=>{
            socket.off("update-users", handleSocketUpdate);
        }
    }, [mutateAddUser, mutateUpdateUser, mutateDeleteUser])

    return (
        <>
            <ConfirmDialog
                open={openDialog === uDialogTypes.DELETE_USER}
                title="Delete User"
                icon={
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                }
                content={`Are you sure you want to delete the user ${selectedUser?.username}?`}
                onClose={()=>{
                    setOpenDialog(uDialogTypes.NONE);
                }}
                onConfirm={handleDeleteUser}
            />

            <DialogForm 
                {...ADD_USER_FORM}
                inputFields={ADD_USER_FORM.inputFields.map(item=>{
                    switch(item.name){
                        case "role":
                            return {...item, options:['Recruiter', "Manager"]};
                        case "password":
                            return {...item, defaultValue:generatedPassword};
                        default:
                            return item;
                    }
                })}
                open={openDialog === uDialogTypes.ADD_USER}
                errors={addUserErrors}
                onSubmit={handleAddUser}
                onClose={()=>{
                    setOpenDialog(uDialogTypes.NONE);
                    setAddUserErrors({});
                }}
            />

            <DialogForm 
                {...RESET_USER_PASSWORD_FORM}
                inputFields={RESET_USER_PASSWORD_FORM.inputFields.map(item=>{
                    switch(item.name){
                        case 'user':
                            return {...item, options:users.filter(user=>!isSuperAdmin(user)).map(user=>user.username)};
                        case 'password':
                            return {...item, defaultValue:generatePassword()};
                        default:
                            return item;
                    }
                })}
                open={openDialog === uDialogTypes.RESET_PASSWORD}
                onClose={()=>setOpenDialog(uDialogTypes.NONE)}
                onSubmit={handleResetPassword}
            />

            <DialogForm 
                {...EDIT_USER_FORM}
                inputFields={EDIT_USER_FORM.inputFields.map(item=>{
                    switch(item.name){
                        case "role":
                            return {...item, options:['Recruiter', "Manager"], defaultValue:selectedUser?.role};
                        default:
                            return {...item, defaultValue:(selectedUser && selectedUser[item.name])};
                    }
                })}
                open={openDialog === uDialogTypes.EDIT_USER}
                onClose={()=>setOpenDialog(uDialogTypes.NONE)}
                onSubmit={handleEditUser}
            />
            
            {
                isMobile ? 
                // Mobile View
                <div className="home-container" style={{height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"}}>
                    <div className="users-header-buttons">
                        <FillButton 
                            variant="outlined" 
                            className={"add-user-btn"}
                            startIcon={<PlusIcon className="adduser-svg-icon" />}
                            onClick={()=>setOpenDialog(uDialogTypes.ADD_USER)}
                            value={USER_LIMIT_REACHED ? "Max users reached" : "Add User"}
                            disabled={USER_LIMIT_REACHED}
                        />    
                        
                        <FillButton 
                            variant="outlined" 
                            className={"reset-password-btn"}
                            startIcon={<ResetPasswordIcon className="resetpassword-svg-icon" />}
                            onClick={()=>setOpenDialog(uDialogTypes.RESET_PASSWORD)}
                            value={"Reset User Password"}
                        />    
                    </div>
                    
                    <CustomGrid 
                        gridId={"usersTable"}
                        rowData={users}
                        columnDefs={columnDefs}
                    />
                </div>
                :

                // Desktop View
                <div className="users-container">
                    <Paper
                        elevation={1}
                        sx={{
                            width:"93%",
                            marginTop:"0.5rem",
                            padding:"0.5rem 1rem",
                            height:"100%",
                            borderRadius:"32px",
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >

                        <div className="users-header-buttons">
                            <FillButton 
                                variant="outlined" 
                                className={"add-user-btn"}
                                startIcon={<PlusIcon className="adduser-svg-icon" />}
                                onClick={()=>setOpenDialog(uDialogTypes.ADD_USER)}
                                value={USER_LIMIT_REACHED ? "Max users reached" : "Add User"}
                                disabled={USER_LIMIT_REACHED}
                            />    
                            
                            <FillButton 
                                variant="outlined" 
                                className={"reset-password-btn"}
                                startIcon={<ResetPasswordIcon className="resetpassword-svg-icon" />}
                                onClick={()=>setOpenDialog(uDialogTypes.RESET_PASSWORD)}
                                value={"Reset User Password"}
                            />    
                        </div>

                        <CustomGrid 
                            gridId={"usersTable"}
                            rowData={users}
                            columnDefs={columnDefs}
                        />
                    </Paper>
                </div>
            }
        </>
    )
}

export default Users;