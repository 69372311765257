import { usersAPI } from "./axiosIstances";

export const fetchUsers = async () => {
    const response = await usersAPI.get("/");
    return response.data;
}

export const addUser = async (userData) => {
    const response = await usersAPI.post("/", userData);
    return response.data;
}

export const updateUser = async ([userID, update]) => {
    const response = await usersAPI.patch(`/${userID}`, update);
    return response.data;
}

export const deleteUser = async (userID) => {
    await usersAPI.delete(`/${userID}`);
    return userID;
}