import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Login, Users, DocumentList, Home, ErrorPage } from '@pages';
import Dashboard from './pages/Dashboard/Dashboard';
import { useAuth } from '@contexts/AuthContext';
import { LoadingIndicator } from '@components';
import { isSuperAdmin } from 'utils/accessControl';
import { HelpAndSupport, RankAndAnalyze } from './pages';
import { pageErrors } from '@constants/errorPageInfo';

function App() {
  const { isLoggedIn, user, loading } = useAuth();

  window.onbeforeunload = (event)=>{
    event.preventDefault(); // prevent un-intentional reload while uploading
  }

  return (
    <div className="App">
      <ToastContainer autoClose={5000} />
      <Routes>
        <Route path='/' element={
          loading ? <LoadingIndicator /> :
          isLoggedIn ? <Dashboard /> :
          <Navigate to={'/auth/login'} /> 
        }>
            <Route index element={<Home />} />
            <Route path="users" element={
                isSuperAdmin(user) ? <Users /> : <ErrorPage error={pageErrors.FORBIDDEN} />
                // <ErrorPage errorCode={403} errorText={"UnAuthorized"} />
            }></Route>
            <Route path="resumes" element={
                <DocumentList key={'resumes'} documentType={'resumes'} />
            }></Route>
            <Route path="job-descriptions" element={
                <DocumentList key={'job-descriptions'} documentType={'job-descriptions'} />
            }></Route>
            <Route path="rank-and-analyze" element={
                <RankAndAnalyze />
            }></Route>
            <Route path="help-and-support" element={
                <HelpAndSupport />
            }></Route>
        </Route>
        <Route path='/auth/login' element={
          isLoggedIn ? <Navigate to={"/"} /> :
          <Login />
        } />
        <Route path='/users' element={<Users />} />
        <Route path='/*' element={<ErrorPage error={pageErrors.PAGE_NOT_FOUND} />} />
      </Routes>
    </div>
  );
}

export default App;
