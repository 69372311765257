import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { CrossCircleIcon } from "@icons/DialogsIcons";
import { CustomSelect, CustomTextField, CustomAutoComplete, CustomFileInput } from "./CustomMUIInputs";

const DialogForm = ({title, open=true, actionButtons=[], errors={}, inputFields=[], defaultOptions={}, onClose=()=>{}, onSubmit=()=>{}}) => {

    return (
        <Dialog fullWidth open={open} onClose={onClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                component:"form",
                onSubmit:onSubmit
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black",
                    position:"relative"
                }}
            >
                <div>{title}</div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={onClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >  
                {
                    inputFields.map((item, index)=>{
                        switch(item.type){
                            case 'select':
                                return <CustomSelect key={index} {...defaultOptions} {...item} 
                                        style={{
                                            marginBottom : (index < inputFields.length-1) ? '0.5rem' : ""
                                        }}
                                    />;
                            case 'text-field':
                                return <CustomTextField key={index} error={!!errors[item.name]} helperText={errors[item.name]} {...defaultOptions} {...item} />;
                            case 'autocomplete':
                                return <CustomAutoComplete key={index} {...defaultOptions} {...item} />;
                            case 'attachments':
                                return <CustomFileInput key={index} multiple {...item}  type="file" />
                            default:
                                return "No Input fields with given type found";
                        }
                    })
                }            
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"center"}}>
                {
                    actionButtons.map((item, index)=>(
                        <Button
                            key={index} 
                            type={item.type || "submit"}
                            variant="contained"
                            style={{
                                background:"#EA723C",
                                textTransform:"none",
                                width:"100%",
                                height:"2.8rem",
                                margin:"0.25rem 1.5rem 0.5rem 1.5rem",
                                borderRadius:"6px",
                                fontWeight:"600",
                                ...item.style
                            }} 
                        >{item.name}</Button>
                    ))
                }
            </DialogActions>
        </Dialog>
    )
}

export default DialogForm;
