const RESUME_FEILDS = {
    'filename' : 'Filename',
    'name' : "Name",
    "email" : "Email",
    "mobile_number" : "Mobile Number",
    "skills" : "Skills",
    'work_experience' : "Work Experience (in Years)"
}

const JD_FIELDS = {
    'filename' : "Filename",
    'job_title' : 'Job Title',
    'company_name' : "Company Name",
    'location' : "Location",
    'required_skills' : 'Required Skills',
    'required_experience_in_years' : "Required Experience (in Years)"
}

export { RESUME_FEILDS, JD_FIELDS };