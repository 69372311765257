import "./document-list.css";
import { useEffect, useRef, useState } from "react";
import { CircularProgress, Fade, Chip, Paper } from "@mui/material";
import { AutorenewOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { DeleteIcon, PlusIcon } from "@icons/ActionsIcons";
import { FillButton, CustomGrid } from "@components";
import socket from "socket";
import { toast } from "react-toastify";
import { DocumentTypeIcon } from "@icons/FilesIcons";
import Lottie from "react-lottie";
import deleteAnimation from "@animations/deleteAnimation.json";
import useResponsiveView from "@hooks/useResponsiveView";
import { useDocuments } from "@contexts/DocumentsContext";
import { useAuth } from "@contexts/AuthContext";
import { ActionsRenderer, FilenameRenderer, PreviewRenderer } from "@renderers/documents-renderers";
import { documentsDialogTypes as dDialogTypes } from "@constants/dialogsEnums";
import ConfirmDialog from "@components/ConfirmDialog";
import { DialogForm, DocumentPreview } from "@components/index";
import { RENAME_DOCUMENT } from "@constants/forms";
import { getFormJson } from "@utils/formUtils";
import UploadJDDialog from "@components/UploadDialogs/UploadJDDialog";

const DocumentList = ({documentType}) => {
    const isMobile = useResponsiveView();
    const { documents, uploadFile, isStale, syncDocuments, mutateDeleteDocument, mutateUpdateDocument } = useDocuments(documentType);
    const { user } = useAuth();
    const DOCUMENT_LIMIT_REACHED = documents?.length >= (documentType === 'resumes' ? user?.plan.max_active_resumes : user?.plan.max_open_jobs);
    
    const [deletingMultiple, setDeletingMultiple] = useState(false);
    const [openDialog, setOpenDialog] = useState(dDialogTypes.NONE);
    const [RenameErrors, setRenameErrors] = useState({});

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const [columnDefs, setColumnsDefs] = useState([
        { headerName: 'Name', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:5,
            minWidth : isMobile ? 350 : undefined,
            headerClass:"not-centered-header-cell",
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",

            },
            cellRenderer:FilenameRenderer,
            cellClass:"name-cell",
            checkboxSelection:true, headerCheckboxSelection:true, headerCheckboxSelectionFilteredOnly:true,
        },
        { headerName: 'Preview', cellClass:"centered-cell", cellRenderer:PreviewRenderer, 
            cellRendererParams:{
                onPreview:(document)=>{
                    setSelectedDocument(document);
                    setOpenDialog(dDialogTypes.PREVIEW_DOCUMENT);
            }}},
        { headerName: 'Actions', cellClass:"centered-cell", cellRenderer:ActionsRenderer, 
            cellRendererParams:{
                onEdit:(document)=>handleAction(document, 'edit'),
                onDelete:(document)=>handleAction(document, 'delete'),
                disabled:!user?.access_delete,
                this : "hello world"
        }},
        {
            headerName: 'Created At',
            field: 'created_at',
            sortable: true,
            hide: true,  // don't need to show the column - needs for sorting only
            sort: 'desc',
        },
    ])

    const handleUploadDocuments = (event)=>{
        const files = event.target?.files;
        if(!files) return;
        for(let i=0; i<files.length; i++){
            uploadFile(files[i], documentType, true);
        }
        event.target.value = '';
    }

    const handleRename = async (event)=>{
        event.preventDefault();
        const formJson = getFormJson(event);
        if(!formJson.filename || !formJson.filename.trim()){
            setRenameErrors({'filename' : "Empty name not allowed"});
            return;
        }
        if(formJson.filename.includes("/")){
            setRenameErrors({"filename" : "Invalid name, '/' not allowed" });
            return;
        }

        try{
            await mutateUpdateDocument({data:[selectedDocument?._id, {filename : formJson.filename}]});
            toast.success("File renamed successfully", {closeOnClick:true});
        }catch(error){
            toast.error(error.message);
        }finally{
            setOpenDialog(dDialogTypes.NONE);
            setRenameErrors({});
        }
    }

    const handleDeleteDocuments = async (event)=>{
        try{
            if(openDialog === dDialogTypes.DELETE_DOCUMENT){
                await mutateDeleteDocument({data:selectedDocument?._id});
                toast.success("File deleted successfully", {closeOnClick:true})
                setOpenDialog(dDialogTypes.NONE);
            }else{
                // multi
                setOpenDialog(dDialogTypes.NONE);
                setDeletingMultiple(true);
                await mutateDeleteDocument({data:selectedDocuments?.map(item=>item._id), isBatch:true});
                setDeletingMultiple(false);
                toast.success(selectedDocuments.length + " file(s) deleted successfully", {closeOnClick:true});
            }
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }finally {
            setOpenDialog(dDialogTypes.NONE);
        }
    }

    const handleAction = (document, type)=>{
        setSelectedDocument(document);
        switch(type){
            case 'edit':
                setOpenDialog(dDialogTypes.RENAME_DOCUMENT);
                break;
            case 'delete':
                setOpenDialog(dDialogTypes.DELETE_DOCUMENT);
                break
            default:
                break;
        }
    }
    
    useEffect(()=>{
        setColumnsDefs(prev=>prev.map(value=>{
            return value.headerName === "Actions" ?
            {
                ...value,
                cellRendererParams:{...value.cellRendererParams, disabled:!user?.access_delete}
            }
            : value
        }))
    }, [user]);

    return (
        <>
            <ConfirmDialog
                open={openDialog === dDialogTypes.DELETE_DOCUMENT} title="Remove Document"
                icon={
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                }
                content={`Are you sure you want to delete the document ${selectedDocument?.filename}?`}
                onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
                onConfirm={handleDeleteDocuments}
            />

            <ConfirmDialog
                open={openDialog === dDialogTypes.DELETE_MULTI} title="Remove Document(s)"
                icon={
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                }
                content={`Are you sure you want to delete selected ${selectedDocuments?.length} documents?`}
                onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
                onConfirm={handleDeleteDocuments}
            />

            <DialogForm 
                {...RENAME_DOCUMENT}
                inputFields={RENAME_DOCUMENT.inputFields.map(item=>{
                    switch(item.name){
                        case 'filename':
                            return {...item, defaultValue:selectedDocument?.filename};
                        case 'cleaned_text':
                            return {...item, defaultValue:selectedDocument?.cleaned_text};
                        default:
                            return item
                    }
                })}
                open={openDialog === dDialogTypes.RENAME_DOCUMENT}
                errors={RenameErrors}
                onSubmit={handleRename}
                onClose={()=>{
                    setOpenDialog(dDialogTypes.NONE)
                    setRenameErrors({});
                }}
            />

            <DocumentPreview 
                open={openDialog === dDialogTypes.PREVIEW_DOCUMENT}
                document={selectedDocument}
                onClose={()=>setOpenDialog(dDialogTypes.NONE)}
            />

            {
                documentType === 'job-descriptions' ?
                <UploadJDDialog
                    open={openDialog === dDialogTypes.UPLOAD_DOCUMENT}
                    onClose={()=>setOpenDialog(dDialogTypes.NONE)}
                    onSubmit={handleUploadDocuments}
                /> : null
            }

            {
                isMobile ? 
                // Mobile View
                <div className="home-container" style={{height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"}}>
                    <div className="document-list-header-buttons">
                        <div
                            style={{
                                display:"flex",
                                flexDirection:"column",
                                width:"70%"
                            }}
                        >
                            <input type="file" name="uploadDocuments" id={"uploadDocuments-"+documentType} multiple style={{display:"none"}} 
                                accept=".pdf, .docx, .txt"
                                onChange={handleUploadDocuments}
                            />

                            <FillButton 
                                variant="outlined" 
                                className={`add-document-btn ${user?.access_post ? null : "disabled"}`}
                                startIcon={<PlusIcon className="adddoc-svg-icon" />}
                                onClick={()=>{
                                    if(documentType === 'job-descriptions'){
                                        setOpenDialog(dDialogTypes.UPLOAD_DOCUMENT);
                                        return;
                                    }
                                    document.getElementById("uploadDocuments-"+documentType).click()
                                }}
                                id={documentType + 'UploadButton'}
                                disabled={DOCUMENT_LIMIT_REACHED || !user?.access_post}
                                value={DOCUMENT_LIMIT_REACHED ? `Max ${documentType === "resumes" ? "Resumes" : "JDs"} reached` : "Upload Document"}
                            />
                            <div className="document-list-header-helper" style={{padding:"0.5rem 0rem"}}><DocumentTypeIcon /> <p>txt, docx and pdf, &lt;= 20MB</p></div>
                        </div>
                        {
                            selectedDocuments.length ?
                            <LoadingButton
                                variant="contained" 
                                style={{
                                    background:user?.access_delete ?"#FF4848" : "lightgray",
                                    border:"1px solid " + (user?.access_delete ? "#FF4848" : "lightgray"),
                                    textTransform:"none",
                                    borderRadius:"0.5rem",
                                    fontFamily:"Montserrat",
                                    padding:"0.3rem",
                                    height:"2.5rem",
                                    alignSelf:"flex-start"
                                }}
                                type="input"
                                inputMode="file"
                                onClick={()=>{
                                    setOpenDialog(dDialogTypes.DELETE_MULTI);
                                }}
                                loadingIndicator={
                                    <CircularProgress color="inherit" sx={{color:"white"}} size={16} />
                                }
                                loading={deletingMultiple}
                                disabled={!user?.access_delete}
                            >
                                <DeleteIcon color="white" />
                            </LoadingButton>
                            : null
                        }
                    </div>

                    <CustomGrid 
                        gridId={`${documentType}Table`}
                        rowData={documents}
                        columnDefs={columnDefs}
                        rowSelection='multiple'
                        suppressRowClickSelection
                        onRowSelected={(event)=>{
                            setSelectedDocuments(event.api.getSelectedRows());
                        }}
                    >
                        <Fade in={isStale} mountOnEnter unmountOnExit>
                            <div
                                style={{
                                    position:"absolute",
                                    width:"calc(95% - 1rem)",
                                    display:"flex",
                                    justifyContent:"center",
                                    zIndex:"5",
                                    marginTop:"3.3rem",
                                }}
                            >
                                <Chip 
                                    icon={<AutorenewOutlined style={{color:"white"}} />} 
                                    onClick={syncDocuments} 
                                    style={{background:"#EA723C", font:"inherit", color:"white"}} 
                                    label={"Load new documents"} 
                                />
                            </div>
                        </Fade>
                    </CustomGrid>
                </div>

                :
                
                // Desktop View
                <div className="document-list-container">
                    <Paper
                        elevation={1}
                        sx={{
                            width:"93%",
                            marginTop:"0.5rem",
                            padding:"0.5rem 1rem",
                            height:"100%",
                            borderRadius:"32px",
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        <div className="document-list-header-buttons">
                            <div
                                style={{
                                    display:"flex",
                                    flexGrow:1,
                                    alignItems:"center"
                                }}
                            >
                                <input type="file" name="uploadDocuments" id={"uploadDocuments-"+documentType} multiple style={{display:"none"}} 
                                    accept=".pdf, .docx, .txt"
                                    onChange={handleUploadDocuments}
                                />

                                <FillButton 
                                    variant="outlined" 
                                    className={`add-document-btn ${user?.access_post ? null : "disabled"}`}
                                    startIcon={<PlusIcon className="adddoc-svg-icon" />}
                                    onClick={()=>{
                                        if(documentType === 'job-descriptions'){
                                            setOpenDialog(dDialogTypes.UPLOAD_DOCUMENT);
                                            return;
                                        }
                                        document.getElementById("uploadDocuments-"+documentType).click()
                                    }}
                                    id={documentType + 'UploadButton'}
                                    disabled={DOCUMENT_LIMIT_REACHED || !user?.access_post}
                                    value={DOCUMENT_LIMIT_REACHED ? `Max ${documentType === "resumes" ? "Resumes" : "JDs"} reached` : "Upload Document"}
                                />
                                <div className="document-list-header-helper"><DocumentTypeIcon  /> <div> <p>supported filetypes : txt, docx and pdf</p> <p>max filesize : 20MB</p></div></div>
                            </div>
                            {
                                selectedDocuments.length ?
                                <LoadingButton
                                    variant="contained" 
                                    style={{
                                        background:user?.access_delete ?"#FF4848" : "lightgray",
                                        border:"1px solid " + (user?.access_delete ? "#FF4848" : "lightgray"),
                                        textTransform:"none",
                                        borderRadius:"12px",
                                        fontFamily:"Montserrat",
                                        fontSize:"1rem",
                                        height: "2.5rem"
                                    }}
                                    startIcon={<DeleteIcon color="white" />}
                                    type="input"
                                    inputMode="file"
                                    onClick={()=>{
                                        setOpenDialog(dDialogTypes.DELETE_MULTI);
                                    }}
                                    loadingIndicator={
                                        <CircularProgress color="inherit" sx={{color:"white"}} size={16} />
                                    }
                                    loading={deletingMultiple}
                                    disabled={!user?.access_delete}
                                >
                                    Delete Selected
                                </LoadingButton>
                                : null
                            }
                        </div>

                        <CustomGrid 
                            gridId={`${documentType}Table`}
                            rowData={documents}
                            columnDefs={columnDefs}
                            rowSelection='multiple'
                            suppressRowClickSelection
                            onRowSelected={(event)=>{
                                setSelectedDocuments(event.api.getSelectedRows());
                            }}
                        >
                            <Fade in={isStale} mountOnEnter unmountOnExit>
                                <div
                                    style={{
                                        position:"absolute",
                                        width:"calc(95% - 1rem)",
                                        display:"flex",
                                        justifyContent:"center",
                                        zIndex:"5",
                                        marginTop:"3.3rem",
                                    }}
                                >
                                    <Chip 
                                        icon={<AutorenewOutlined style={{color:"white"}} />} 
                                        onClick={syncDocuments} 
                                        style={{background:"#EA723C", font:"inherit", color:"white"}} 
                                        label={"Load new documents"} 
                                    />
                                </div>
                            </Fade>
                        </CustomGrid>
                    </Paper>
                </div>
            }
        </>
    )
}

export default DocumentList;