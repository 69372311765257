import { Table, TableCell, TableBody, TableRow, TableContainer, TableHead, Paper, Divider} from "@mui/material";
import { RESUME_FEILDS, JD_FIELDS } from "@constants/tableFields";

const CustomTabelCell = ({sx, ...params})=>{
    return (
    <TableCell 
        sx={{
            fontFamily:"Montserrat",
            fontSize:"0.85rem",
            background:"white",
            width:"50%",
            border:"none",
            ...sx
        }}
        {...params}
    >
        {params.children}
    </TableCell>
)}

const NotDetected = ({text}) => {
    return (
        <span style={{
            color: 'lightgray'
        }}>
            {text ? text : 'Not Detected'}
        </span>
    )
}

const DocumentTableView = ({document}) => {
    return (
        <>
            <TableContainer component={(props)=><Paper elevation={0} {...props} />} style={{width:"99%", top:"0", fontFamily:"Montserrat", border:"1px solid black", borderRadius:"20px"}}>
                <Table sx={{ width: '100%', height:"100%" }} aria-label="simple table">
                    <TableHead>
                    <TableRow style={{background:"white"}}>
                        <CustomTabelCell sx={{fontWeight:"700", background:"#EA723C1A"}}>Field</CustomTabelCell>
                        <CustomTabelCell sx={{fontWeight:"700", background:"#EA723C1A"}}>Value</CustomTabelCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(document.document_type === 'resumes' ? RESUME_FEILDS : JD_FIELDS).map((key, index) => (
                                <TableRow key={index}>
                                    <CustomTabelCell
                                        sx={{
                                            fontWeight:"600"
                                        }}
                                    >{(document.document_type === 'resumes' ? RESUME_FEILDS : JD_FIELDS)[key]}</CustomTabelCell>
                                    <CustomTabelCell>{
                                        ['n/a', 'none', 'null'].includes(String(document[key]).toLocaleLowerCase()) ? <NotDetected /> : document[key]
                                    }</CustomTabelCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default DocumentTableView;