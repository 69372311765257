export const status = {
    SUCCESS : "success",
    INVALID : "invalid",
    FAILED : "failed",
    REQUESTED : "requested",
    RECEIVED : "received"
}

export const errors = {
    EXTRACT_FAILED : "extract-failed",
    PARSE_FAILED : "parse-failed",
    TYPE_INVALID : "type-invalid",
    DUPLICATE_FILE : "duplicate-file",
    ABORT : "abort",
    UNSUPPORTED_FILETYPE : "unsupported-filetype",
    FILESIZE_EXCEEDED : "filesize-exceeded",
    PLAN_LIMIT : "plan-limit"            
}