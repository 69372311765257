import { DeleteIcon, EditDocumentIcon } from "@icons/ActionsIcons";
import { Tooltip, IconButton } from "@mui/material";

const ActionsRenderer = ({data:document, onDelete=(document)=>{}, onEdit=(document)=>{}, ...params})=>{

    return (
        <>
            <Tooltip title="Rename" arrow>
                <IconButton className="action-btn" onClick={()=>onEdit(document)}>
                    <EditDocumentIcon className="action-svg-icon" />        
                </IconButton>
            </Tooltip>
            
            {
                params.disabled ? 
                <IconButton disabled={params.disabled}>
                    <DeleteIcon color={"lightgray"} />        
                </IconButton>
                :
                <Tooltip title="Delete" arrow>
                    <IconButton
                        className="action-btn"
                        onClick={(event)=>{
                            onDelete(document);
                        }}
                    >
                        <DeleteIcon className="action-svg-icon" />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}

export default ActionsRenderer;
