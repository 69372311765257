const ScoreRenderer = (params)=>{
    return (
        <div
            style={{
                fontSize:"1rem",
            }}
        >
            {(params.value * 100).toFixed(2)}%
        </div>
    )
}

export default ScoreRenderer;
