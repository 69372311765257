import { CustomHeadRow, CustomRow } from "@components/CustomTableComponents";
import useResponsiveView from "@hooks/useResponsiveView";
import { RankAndAnalyzeIcon } from "@icons/DashboardIcons";
import { CrossCircleIcon, YesIcon, CrossIcon } from "@icons/DialogsIcons";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useEffect, useState } from "react";

const NotDetected = ({text}) => {
    return (
        <span style={{
            color: 'lightgray'
        }}>
            {text ? text : 'Not Detected'}
        </span>
    )
}

const AnalyzeRenderer = (params)=>{
    const isMobile = useResponsiveView();
    const [isOpen, setIsOpen] = useState(false);
    const [requiredExperience, setRequiredExperience] = useState(null);
    const [candidateExperience, setCandidateExperience] = useState(null);
    const [skillsFound, setSkillsFound] = useState(null);
    const [error, setError] = useState(false);
    const handleClose = ()=>{
        setIsOpen(false);   
    }
    useEffect(()=>{
        if(!isOpen){return}
        let resume = null;
        let jobDescription = null;
        let documentMap = {};
        
        for(let document of params.documents){
            documentMap[document._id] = document;
        }

        if(params.resume){
            resume = params.resume;
            jobDescription = documentMap[params.data._id];
        }
        else if(params.jobDescription){
            resume = documentMap[params.data._id];
            jobDescription = params.jobDescription;
        }

        console.log(resume)

        if(!(resume && jobDescription)){
            setError(true);
            return;
        }
        if(jobDescription.required_experience_in_years && !['none', 'n/a', 'null'].includes(String(jobDescription.required_experience_in_years).toLowerCase())){
            setRequiredExperience(
                jobDescription.required_experience_in_years
            )
        }
        if(resume.work_experience && !['none', 'n/a', 'null'].includes(String(resume.work_experience).toLowerCase())){
            setCandidateExperience(
                resume.work_experience
            )
        }
        if(jobDescription.required_skills && !['none', 'n/a', 'null'].includes(String(jobDescription.required_skills).toLowerCase())){
            const required_skills = jobDescription.required_skills.split(",").map(x=>x.trim());
            if (!resume.skills || ['none', 'n/a', 'null'].includes(String(resume.skills).toLowerCase())){
                resume.skills = "none";
            }
            
            setSkillsFound(
                required_skills.map((item)=>{
                    return item.length > 2 ?
                    [item, String(resume.cleaned_text).toLowerCase().includes(String(item).toLowerCase())]
                    : [item, String(resume.skills).toLowerCase().includes(String(item).toLowerCase())]
                })    
            )
        }

    }, [isOpen, params.documents, params.jobDescription, params.resume, params.data])
    return (
        error ? null :
        <>
            <Dialog fullWidth open={isOpen} onClose={handleClose} 
                PaperProps={{
                    style:{borderRadius:"1rem", padding:"1rem", background:"white"},
                }}
            >
                <DialogTitle
                    style={{
                        textAlign:"center",
                        fontFamily:"Montserrat",
                        fontWeight:"700",
                        color:"black",
                        position:"relative"
                    }}
                >
                    <div>Analyze</div>
                    <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                        <CrossCircleIcon color="black" height="25" width="25" />
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        height:"50svh",
                        padding:isMobile ? 0 : undefined
                    }}
                >
                    <CustomHeadRow key={1} column1={"Required Experience"} column2={"Candidate Experience"}/>
                    <div
                        style={{
                            width:"calc(100% - 1rem)",
                            borderRadius:"16px",
                            border:"1px solid #1E1E1E8B",
                            marginBottom:"1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center"
                        }}
                    >
                        <CustomRow 
                            column1={requiredExperience ? requiredExperience + ' Year(s)' : <NotDetected />} 
                            column2={candidateExperience ? candidateExperience + ' Year(s)' : <NotDetected />}
                            borderFlag={false}
                        />
                    </div>
                    
                    <CustomHeadRow key={2} column1={"Required Skills"} column2={"Found in Resume"}/>
                    <div
                        style={{
                            width:"calc(100% - 1rem)",
                            borderRadius:"16px",
                            border:"1px solid #1E1E1E8B",
                            marginBottom:"1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center"
                        }}
                    >
                        {
                            !skillsFound ? <div align="center" style={{fontSize:"1rem", padding:"1rem", fontFamily:"Montserrat"}}><NotDetected text={"No Skills detected in Job description"} /></div> :
                            skillsFound.map(([skill, found], index)=>(
                                <CustomRow 
                                    key={index}
                                    column1={skill}
                                    column2={found ? <YesIcon color="#00B350" /> : <CrossIcon color="#FF5E62" />}
                                    borderFlag={index !== (skillsFound.length - 1)}
                                />
                            ))
                        }
                    </div>
                </DialogContent>
            </Dialog>

            <IconButton onClick={()=>setIsOpen(true)}>
                <RankAndAnalyzeIcon />
            </IconButton>
        </>
    )
}

export default AnalyzeRenderer;