import { useState, useEffect } from 'react';
import { isMobile as initialIsMobile } from 'react-device-detect';

const useResponsiveView = () => {
  const [isMobile, setIsMobile] = useState(initialIsMobile);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

export default useResponsiveView;