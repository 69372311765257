import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ConfirmDialog = ({open=false, title="", icon=null, content="", confirmText="Yes", declineText="No", onClose=()=>{}, onConfirm=()=>{}, onDecline=null})=>{
    return (
        <Dialog fullWidth open={open} onClose={onClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black"
                }}
            >
                <div>{title}</div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                }}
            >
                {icon}
                <DialogContentText
                    style={{
                        fontFamily:"Montserrat",
                        marginTop:"0.5rem",
                        color:"black",
                        fontWeight:"600",
                        textAlign:"center",
                        width:"80%"
                    }}
                >{content}</DialogContentText>
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"space-evenly"}}>
                <Button 
                    variant="outlined"
                    style={{
                        fontWeight:600, 
                        fontSize:"1rem",
                        background:"white",
                        border:"1px solid black",
                        color:"black",
                        width:"12rem",
                        textTransform : 'none',
                        borderRadius:"16px"
                    }} 
                    onClick={()=>{
                        if(onDecline){
                            onDecline();
                        }
                        onClose();
                    }}
                >{declineText}</Button>
                <Button 
                    variant="outlined"
                    style={{
                        fontWeight:600, 
                        fontSize:"1rem",
                        background:"#EA723C",
                        border:"1px solid #EA723C",
                        color:"white",
                        width:"12rem",
                        textTransform : 'none',
                        borderRadius:"16px"
                    }} 
                    onClick={()=>{
                        onConfirm();
                    }}
                >{confirmText}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;