import { Tooltip } from "@mui/material";
import { useState } from "react";

const FilenameRenderer = (params) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <Tooltip title={params.value} open={isOpen} onClick={()=>setIsOpen(true)} onClose={()=>setIsOpen(false)}
                style={{
                    textOverflow:"ellipsis"
                }}
            >
                <div>
                    {params.value}
                </div>
            </Tooltip>
        </div>
    )
}

export default FilenameRenderer;