import React from 'react';
import ReactDOM from 'react-dom/client';
import '@styles/index.css';

import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DocumentsProvider } from '@contexts/DocumentsContext';
import { ErrorBoundary } from 'react-error-boundary';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary fallbackRender={(error)=><div>{error?.message || "Something went wrong"}</div>}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthProvider>
            <DocumentsProvider>
              <App />
            </DocumentsProvider>
          </AuthProvider>
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
