import "./upload-status-dialog.css";
import { IconButton, CircularProgress, Collapse, Stack, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, LinearProgress } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import { KeyboardArrowUp, KeyboardArrowDown, CloseOutlined, CircleOutlined, ErrorOutline } from '@mui/icons-material';
import { useEffect, useRef, useState } from "react";
import socket from "socket";
import { AbortIcon, CrossCircleIcon, CheckCircleIcon, ErrorIcon } from "@icons/DialogsIcons";

import { errors as errorsEnum, status as statusEnum } from "@constants/uploadDialogEnums";
import { useDocuments } from "@contexts/DocumentsContext";

const FailedFilesDialog = ({item, onUpload, onClose}) => {
    const getErrorText = (error)=>{
        switch(error){
            case errorsEnum.EXTRACT_FAILED:
                return "Text extraction failed, Due to invalid files like unparsable pdfs, or empty files";
            case errorsEnum.PARSE_FAILED:
                return "Failed to parse the file, due to either parser being busy or timeout please try again later";
            case errorsEnum.TYPE_INVALID:
                return `File Type Mismatch, The file does not seem to be a ${item.documentType}. If you believe this is a mistake, you can still proceed with the upload.`;
            case errorsEnum.DUPLICATE_FILE:
                return `Duplicate File Detected - ${item.attachment}`;
            case errorsEnum.ABORT:
                return `Task was aborted by user`;
            case errorsEnum.UNSUPPORTED_FILETYPE:
                return `File type is not supported`;
            case errorsEnum.FILESIZE_EXCEEDED:
                return `File size limit exceeded - Maximum of 20MB supported`;
            case errorsEnum.PLAN_LIMIT:
                return 'Plan limit reached!'
            default:
                return "Unexpected error encountered"
        }
    }
    return item === null ? null : (
        <Dialog fullWidth open={item !== null} onClose={onClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    position:"relative"
                }}
            >
                <div>Failed Upload</div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={onClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                }}
            >
                <DialogContentText
                    style={{
                        width:"100%",
                        textAlign:"center",
                        overflow:"hidden",
                        textOverflow:"ellipsis"
                    }}
                >
                    <b style={{color:"black"}}>Filename :</b> "{item.filename}"
                </DialogContentText>
                <DialogContentText
                    style={{
                        width:"100%",
                        textAlign:"center"
                    }}
                >
                    <b style={{color:"black"}}>Reason   :</b> {getErrorText(item.error)}
                </DialogContentText>
            </DialogContent>
            {
                !(item.error === errorsEnum.TYPE_INVALID || item.error === errorsEnum.PARSE_FAILED) ? null : 
                <DialogActions style={{display:"flex", justifyContent:"center"}}>
                    <Button
                        variant="contained"
                        style={{
                            fontWeight:600, 
                            fontSize:"1rem",
                            background:"#EA723C",
                            border:"1px solid #EA723C",
                            color:"white",
                            width:"80%",
                            borderRadius:"16px"
                        }} 
                        onClick={()=>onUpload(item)}
                    >Upload</Button>
                </DialogActions>
            }
        </Dialog>
    )
}

const UploadStatusDialog = ({ title, documentType, active, onExpand, onClose }) => {
    const [open, setOpen] = useState(true);
    const [expand, setExpand] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const { uploadQueue:queue, setUploadQueue, uploadFile, abortFileUpload } = useDocuments(documentType);
    const [ progress, setProgress ] = useState(null);

    useEffect(() => {
        const isLoading = Object.values(queue).some(item => item.status === statusEnum.REQUESTED || item.status === statusEnum.RECEIVED);
        setLoading(isLoading);
        if(Object.values(queue).length === 0){
            setProgress(-5);
        }else if(!isLoading){
            setProgress(100);
        }
        else{
            setProgress((Object.values(queue).filter(item=>!(item.status === statusEnum.REQUESTED || item.status === statusEnum.RECEIVED)).length / Object.values(queue).length) * 100)
        }
    }, [queue]);

    useEffect(()=>{
        setExpand(active);
    }, [active])

    const handleExpand = ()=>{
        setExpand(prev => !prev);
        onExpand();
    }

    const handleExited = ()=>{
        // when exited : animation complete -> set Queue to empty and open to true for next use
        onClose();
        setUploadQueue(prev=>prev.filter(item=>item.documentType !== documentType));
        setOpen(true);
    }

    const handleClose = ()=>{
        if(loading){
            console.log("abort all ?")
            for(const item of queue){
                abortFileUpload(item);
            }
            return;
        }
        setOpen(false);
    }

    return (
        <>
        <FailedFilesDialog item={selectedItem} onUpload={()=>{
            uploadFile(selectedItem?.file, selectedItem?.documentType, false, selectedItem?.fileId);
            setSelectedItem(null);
        }} onClose={()=>setSelectedItem(null)} />
        
        <Slide in={!!queue.length && open} direction="left" onExited={handleExited} mountOnEnter unmountOnExit >
            <div className={"upload-status-container"}>
                <Stack
                    direction={'row'}
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    padding={"0.3rem"}
                    borderRadius={"0.5rem"}
                    bgcolor={"#EA723C"}
                    zIndex={1}
                    color={'white'}
                    position={"relative"}
                >
                    <LinearProgress variant="determinate" value={progress}
                        sx={{
                            bottom:"0px",
                            height:"3px",
                            background:'red',
                            width:"calc(100% - 0.6rem)",
                            borderRadius:"0.5rem",
                            position:"absolute",
                            zIndex:"0",
                            '&.MuiLinearProgress-root': {
                                backgroundColor: '#FFFFFFCC',
                            },
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: '#0ABC31', 
                            },
                    }}/>
                    <IconButton aria-label={expand ? "Collapse" : "Expand"} onClick={handleExpand}>
                        {expand ? <KeyboardArrowUp style={{ color: "white" }} fontSize="medium" /> : <KeyboardArrowDown style={{ color: "white" }} fontSize="medium" />}
                    </IconButton>
                    <div style={{ fontSize: "1.1rem", zIndex:1 }}>
                        {Object.values(queue).filter(item => item.status === statusEnum.SUCCESS).length.toString().padStart(2, ' ')} of {Object.values(queue).length} {title === 'resume' ? 'resume' : 'jd'}(s) uploaded
                    </div>
                    <IconButton aria-label={loading ? "Loading" : "Close"} onClick={handleClose}>
                        <CloseOutlined style={{ color: "white" }} />
                    </IconButton>
                </Stack>
                <Collapse in={expand}>
                    <Stack spacing={0.5} marginTop={'0.3rem'} maxHeight={"15rem"} overflow={"auto"}  padding={"0.3rem"} border={"1px solid black"} borderRadius={'0.5rem'} bgcolor={'white'}>
                        {queue.map((item, idx) => (
                            <div key={idx} className={"upload-status-item" + (item.status === statusEnum.FAILED ? " failed" : "")} style={{ borderRadius: "0.5rem" }}
                                onClick={(event)=>{
                                    if(item.status !== statusEnum.FAILED){
                                        return;
                                    }
                                    setSelectedItem(item);
                                }}
                            >
                                <div
                                    style={{
                                        color: getStatusColor(item.status),
                                        fontSize: "1.3rem",
                                        maxWidth: "75%",
                                        textOverflow: "ellipsis",
                                        overflowX: "hidden",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    {/* 
                                        Adding Tooltip introduce scrollbar blink issues - 
                                        scrolling tooltip out of screen will cause scrool to appear and then disappear in few milliseconds. 
                                    */}
                                    {item.filename}
                                </div>
                                <div>
                                    <IconButton
                                        disabled={![statusEnum.RECEIVED, statusEnum.REQUESTED].includes(item.status)}
                                        style={{padding:0}}
                                        onClick={()=>{
                                            // abort task
                                            abortFileUpload(item);  
                                        }}
                                    >
                                        {getStatusIcon(item.status)}
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </Stack>
                </Collapse>
            </div>
        </Slide>
        </>
    );
};

const getStatusColor = (status) => {
    switch (status) {
        case statusEnum.REQUESTED:
            return '#EBEBEB';
        case statusEnum.RECEIVED:
            return 'black';
        case statusEnum.SUCCESS:
            return '#0ABC31';
        case statusEnum.INVALID:
            return '#B86F01';
        case statusEnum.FAILED:
            return "#E83100";
        default:
            return "black";
    }
};

const getStatusIcon = (status) => {
    switch (status) {
        case statusEnum.RECEIVED:
            return <AbortIcon color="black" />;
        case statusEnum.SUCCESS:
            return <CheckCircleIcon color="#0ABC31" />;
        case statusEnum.INVALID:
            return <ReplayIcon style={{ color: "#B86F01" }} fontSize="medium" />;
        case statusEnum.FAILED:
            return <ErrorIcon color="#C4423D" />;
        case statusEnum.REQUESTED:
            return <AbortIcon color="lightgray" />;
        default:
            return status;
    }
};

export default UploadStatusDialog;
