import { errors, status } from "@constants/uploadDialogEnums";
import useResourceMutation from "@hooks/useResourceMutation";
import { deleteDocument, deleteDocumentBatch, fetchDocuments, updateDocument } from "@services/documentsService";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import socket from "socket";
import { useAuth } from "./AuthContext";
import { documentsAPI } from "@services/axiosIstances";

const DocumentsContext = createContext();
export const useDocuments = (documentType) => {
    const { documents, uploadQueue, ...value } = useContext(DocumentsContext);
    const filteredQueue = documentType ?
        uploadQueue.filter(item=>item.documentType === documentType) 
        :
        uploadQueue
    const filteredDocuments = documentType ? 
        documents.filter(document=>document.document_type === documentType)
        :
        documents;
    return { documents:filteredDocuments, uploadQueue:filteredQueue, ...value}; 
}

export const DocumentsProvider = ({ children }) => {
    const queryKeys = useMemo(()=>['documents'], []);
    const [updates, setUpdates] = useState([]);
    const [pendingUpdates, setPendingUpdates] = useState([]);
    const [isStale, setIsStale] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [uploadQueue, setUploadQueue] = useState([
        // {fileId : 0, filename : "filename", documentType:"resumes", status : status.SUCCESS},
        // {fileId : 1, filename : "filename", documentType:"job-descriptions", status : status.FAILED, error : errors.TYPE_INVALID},
        // {filedId : 2, filename : "filename", documentType:"resumes", status : status.FAILED}
    ]);

    const { data:documents=[] } = useQuery({
        queryKey : queryKeys,
        queryFn : fetchDocuments,
        staleTime : Infinity
    })

    const { 
        mutateAsync:mutateAddDocument, 
    } = useResourceMutation(queryKeys, 'add', (e)=>e); // add document is handled seperately
    
    const { 
        mutateAsync:mutateDeleteDocument, 
    } = useResourceMutation(queryKeys, 'delete', deleteDocument);

    const { 
        mutateAsync:mutateUpdateDocument, 
    } = useResourceMutation(queryKeys, 'update', updateDocument);

    const uploadFile = async (file, documentType, typeCheck=true, oldFileId=undefined) => {
        const ACCEPTED_EXTENSIONS = [".pdf", ".txt", ".docx"];
        const MAX_SIZE = 20 * 1024 * 1024;
        
        const fileId = oldFileId || 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
        const extention = file.name?.split(".").pop().toLowerCase()

        if(!ACCEPTED_EXTENSIONS.includes(`.${extention}`)){
            return setUploadQueue(prev=>[...prev, {
                filename : file.name,
                documentType : documentType,
                status:status.FAILED, 
                error:errors.UNSUPPORTED_FILETYPE
            }])
        }
        
        if(file.size > MAX_SIZE){
            return setUploadQueue(prev=>[...prev, {
                filename : file.name,
                documentType : documentType,
                status : status.FAILED,
                error : errors.FILESIZE_EXCEEDED
            }])
        }

        const controller = new AbortController();
        if(oldFileId){
            setUploadQueue(prev=>prev.map(item=>{
                return item.fileId === oldFileId ? {
                    ...item,
                    status : status.REQUESTED,
                    controller : controller
                } : item
            }))
        }else{
            setUploadQueue(prev=>[...prev, {
                fileId : fileId,
                status : status.REQUESTED,
                filename : file.name,
                documentType : documentType,
                controller : controller,
                // startTime : new Date(), // for debug purpose
                file : file // for re-upload
            }])
        }
        
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileId', fileId);
        formData.set('documentType', documentType);
        formData.set('typeCheck', typeCheck);

        try {
            const result = await documentsAPI.post("/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                signal : controller.signal
            });

            const taskId = result.data;
            setUploadQueue(prev=>prev.map(item=>{
                return (item.fileId === fileId && item.status === status.REQUESTED) ? {
                    ...item,
                    fileId : fileId,
                    status : status.RECEIVED,
                    taskId : taskId
                } : item
            }))
        } catch (error) {
            setUploadQueue(prev=>prev.map(item=>{
                return (item.fileId === fileId) ? {
                    ...item,
                    fileId : fileId,
                    status : status.FAILED,
                    error : error.message
                } : item
            }))
        }
    };

    const abortFileUpload = async (uploadItem) => {
        if([status.SUCCESS, status.FAILED].includes(uploadItem.status)){
            return; // can't abort 
        }
        
        try {    
            if(!uploadItem.taskId){
                uploadItem.controller.abort();
            }else{
                await documentsAPI.delete(`/uploads/${uploadItem.taskId}/abort`);
            }
            setUploadQueue(prev=>prev.map(item=>{
                return item.fileId === uploadItem.fileId ? {
                    ...uploadItem, status:status.FAILED, error:errors.ABORT,
                } : item
            }))
        }catch(error){
            // ignore the errors
        }
        
    }
    
    useEffect(()=>{
        const handleSocketUpdate = (update)=>{
            if(isSyncing){
                setPendingUpdates(prev=>[...prev, update]);
            }else{
                setIsStale(true);
                setUpdates(prev=>[...prev, update]);
            }
        }

        socket.on("update-documents", handleSocketUpdate);
        return ()=>{
            socket.off("update-documents", handleSocketUpdate);
        }
    }, [isSyncing])

    useEffect(()=>{
        socket.on("upload-success", ({file_id})=>{
            setUploadQueue(prev=>prev.map(item=>{
                // const a = item.fileId === file_id ? console.log("Uploaded in : ", (new Date() - item.startTime)/1000) : null;
                return item.fileId === file_id ? {
                    ...item, 
                    status : status.SUCCESS, 
                    file:undefined
                } : item
            }))
        });
        
        socket.on("upload-failed", ({file_id, document_type, error, attachment=null})=>{
            setUploadQueue(prev=>prev.map(item=>{
                // const a = item.fileId === file_id ? console.log("Uploaded failed in : ", (new Date() - item.startTime)/1000) : null;
                return item.fileId === file_id ? {
                    ...item,
                    status : status.FAILED,
                    error : error,
                    attachment
                } : item
            }))
        });

        return ()=>{
            socket.off("upload-success");
            socket.off("upload-failed");
        }
    }, [])

    const syncDocuments = async ()=>{
        setIsSyncing(true);
        for(const {type, payload} of updates){
            try{
                switch(type){
                    case 'add':
                        await mutateAddDocument({data:payload, cacheOnly:true});
                        break;
                    case 'update':
                        await mutateUpdateDocument({data:payload, cacheOnly:true});
                        break;
                    case 'delete':
                        await mutateDeleteDocument({data:payload, cacheOnly:true});
                        break;
                    case 'delete-multi':
                        await mutateDeleteDocument({data:payload, cacheOnly:true}, {isBatch:true});
                        break;
                    default:
                        console.log(`Unrecognized type received for update-users event : ${type}`);
                }
            }catch(error){
                console.log(error);
            }
        }
        setIsStale(false);
        setUpdates(pendingUpdates);
        setPendingUpdates([]);
        setIsSyncing(false);
    }

    const reset = ()=>{
        setIsStale(false);
        setIsSyncing(false);
        setPendingUpdates([]);
        setUploadQueue([]);
        setUpdates([]);
    }

    return (
        <DocumentsContext.Provider value={{documents, isStale, uploadQueue, setUploadQueue, syncDocuments, uploadFile, abortFileUpload, mutateDeleteDocument, mutateUpdateDocument, reset}}>
            {children}
        </DocumentsContext.Provider>
    )
}
