import socket from "../socket.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUser, login as loginFn, logout as logoutFn } from "@services/authService.js";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({
        loginError : "", logoutError : "", userError : ""
    });
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isSocketConnected, setIsSocketConnected] = useState(false);

    const navigate = useNavigate();
    const navigateRef = useRef(navigate);

    const login = useCallback(async (credentials) => {
        setLoading(true);
        try {
            const userData = await loginFn(credentials);
            if(!socket.connected){
                socket.connect();
            }
            setUser(userData);
            setIsLoggedIn(true);
            localStorage.setItem("isLoggedIn", true);
            setErrors(prevErrors=>({...prevErrors, loginError : ""}));
            if(navigateRef.current){
                navigateRef.current("/");
            }
        }catch(error){
            setErrors(prevErrors=>({...prevErrors, loginError:error.message}));
        }finally{
            setLoading(false);
        }
    }, [navigateRef]);

    const logout = useCallback(async () => {
        setLoading(true);
        try {
            await logoutFn();
            setIsLoggedIn(false);
            setErrors(prevErrors=>({...prevErrors, logoutError : ""}));
            setUser(null);
            if(navigateRef.current){
                navigateRef.current("/auth/login");
            }
            localStorage.removeItem('isLoggedIn');
            if(socket.connected){
                socket.disconnect();
            }
        }catch(error){
            setErrors(prevErrors=>({...prevErrors, logoutError:error.message}));
        }finally {
            setLoading(false);
        }
    }, [navigateRef]);

    useEffect(()=>{
        const checkAuth = async ()=>{
            setLoading(true);
            try {
                const userData = await getUser();
                setUser(userData);
                setIsLoggedIn(true);
                setErrors(prevErrors=>({...prevErrors, userError : ""}));
                localStorage.setItem("isLoggedIn", true);
                if(!socket.connected){
                    socket.connect();
                }
            }catch(error){
                setErrors(prevErrors=>({...prevErrors, userError:error.message}));
                if(localStorage.getItem("isLoggedIn")){
                    toast.error("Session Expired", {closeOnClick:true});
                    localStorage.removeItem('isLoggedIn');
                }
            }finally{
                setLoading(false);
            }
        }

        checkAuth();
        
        socket.on("connect", ()=>{
            console.log("socket connected");
            setIsSocketConnected(true);
        }) 
        socket.on("disconnect", ()=>{
            console.log("socket disconnected");
            setIsSocketConnected(false);
        }) 

        socket.on("update-user", (userData)=>{
            setUser(prev=>({...prev, ...userData}));
        })

        socket.on("session-expired", ()=>{
            toast.error("Session Expired", {closeOnClick:true});
            setIsLoggedIn(false);
            setErrors(prevErrors=>({...prevErrors, userError : ""}));
            setUser(null);
            if(navigateRef.current){
                navigateRef.current("/auth/login");
            }
            localStorage.removeItem('isLoggedIn');
            if(socket.connected){
                socket.disconnect();
            }
        })

        return ()=>{
            socket.off("connect");
            socket.off("disconnect");
            socket.off("update-user");
            socket.off("session-expired");
            socket.disconnect();
        }
    }, [navigateRef]);

    return (
        <AuthContext.Provider value={{ user, loading, errors, isLoggedIn, isSocketConnected, login, logout }}>
            {children}
        </AuthContext.Provider>
    )   
}