const TOUR_STEPS = [
    {
        selector : '#resumesPageLink',
        title: "Resumes",
        content : "Upload, Rename or Delete resumes",
        actionCustom : 'click'
    },
    {
        selector : '#resumesUploadButton',
        title : "Upload Button",
        content : 'New resumes can be uploaded here'
    },
    {
        selector : '#jobDescriptionsPageLink',
        title : "Job Descriptions",
        content : 'Upload, Rename or Delete job-descriptions',
        actionCustom : 'click'
    },
    {
        selector : '#job-descriptionsUploadButton',
        title : "Upload Button",
        content : 'New job descriptions can be uploaded here'
    },
    {
        selector : '#rankAndAnalyzePageLink',
        title : "Rank & Analyze",
        content : 'Perform Ranking/Matching of documents',
        actionCustom : 'click'
    },
    {
        selector : '#radio-buttons-group',
        title : "Document Type",
        content : 'First select the type of the document that you want to perform ranking for',
    },
    {
        selector : '#combo-box-demo',
        title : "Select Document",
        content : 'Now select the document',
    },
    {
        selector : '#rank-and-analyze-grid',
        title : "Result",
        content : 'This table shows the matching documents for selected document based on the score and list them in descending order',
    },
]

export default TOUR_STEPS;