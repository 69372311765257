import "./rank-and-analyze.css";
import { useEffect, useRef, useState } from "react";
import { IconButton, FormControlLabel, Radio, Autocomplete, TextField, RadioGroup, Dialog, DialogTitle, DialogContent, DialogContentText, TableContainer, TableRow, TableCell, TableHead, Table, TableBody, Divider, Tooltip, Fade, Button, Paper } from "@mui/material";

import socket from "socket";
import useResponsiveView from "@hooks/useResponsiveView";
import { AutorenewOutlined } from "@mui/icons-material";
import { CustomAutoComplete } from "@components/CustomMUIInputs";
import { FilenameRenderer, PreviewRenderer } from "@renderers/documents-renderers";
import { AnalyzeRenderer, ScoreRenderer } from "@renderers/rank-and-analyze-renderers";
import { CustomGrid, DocumentPreview } from "@components/index";
import { useDocuments } from "@contexts/DocumentsContext";
import { getSimilarityScore } from "@services/documentsService";

const RankAndAnalyze = () => {
    const { documents, isStale, syncDocuments } = useDocuments();

    const [rowData, setRowData] = useState([]);
    const [filter, setFilter] = useState('job-descriptions');
    const [selectedDocument, setSelectedDocument] = useState("");
    const [previewDocument, setPreviewDocument] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);

    const isMobile = useResponsiveView();

    const handleChangeDocument = async (event, newVal) => {
        if(!newVal){
            setSelectedDocument("");
            setRowData([]);
            return;
        }
        setSelectedDocument(newVal);
        if(!newVal) return;

        const data = await getSimilarityScore(newVal?._id);
        setRowData(data.map(item=>{
            const otherDocument = documents.find(innerItem=>innerItem._id === item._id);
            if(!otherDocument){
                return otherDocument
            }
            return {
                _id : item._id,
                score : item.score,
                filename : otherDocument.filename,
            }
        }).filter(item=>!!item))    
    }

    const columnDefs = [
        { headerName: 'Document', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:3,
            minWidth:250,
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",
            },
            cellClass:"name-cell",
            headerClass:"not-centered-header-cell",
            cellRenderer:FilenameRenderer
        },
        { headerName: 'Score', field:'score', sort:"desc", sortable:"true",  cellClass:"centered-cell", cellRenderer:ScoreRenderer },
        { headerName: 'Preview', flex:1, cellClass:"centered-cell", cellRenderer:PreviewRenderer, 
            cellRendererParams:{
                onPreview:(document)=>{
                    setPreviewDocument(documents.find(item=>item._id === document._id));
                    setOpenPreview(true);
        }}},
        { headerName: 'Analyze', flex:1, colId:"analyze", cellClass:"centered-cell", cellRenderer:AnalyzeRenderer, 
            cellRendererParams:{
                resume : filter === 'resumes' ? selectedDocument : undefined,
                jobDescription : filter !== 'resumes' ? selectedDocument : undefined,
                documents : documents
            }
        }
    ]

    const handleUpdateDocuments = ()=>{
        syncDocuments();
        setSelectedDocument("");
        setRowData([]);
    }

    return (
        <div className={isMobile ? "home-container" : "rank-and-analyze-container"} style={ isMobile ? {height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"} : null}>
            <DocumentPreview 
                open={openPreview}
                document={previewDocument}
                onClose={()=>{
                    setOpenPreview(false);
                }}
            />
            
            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:isMobile ? "100%" : "93%",
                    marginTop:isMobile ? null : "0.5rem",
                    padding:"0.5rem 1rem",
                    height:"100%",
                    borderRadius:isMobile ? "20px" : "32px",
                    display:"flex",
                    flexDirection:"column",
                }}
            >
                <div className="rank-and-analyze-header">
                    <RadioGroup
                        row
                        style={{
                            margin:"1rem 0.5rem 0.5rem 1rem"
                        }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="job-descriptions"
                        name="radio-buttons-group"
                        id="radio-buttons-group"
                        value={filter}
                        onChange={(event, newVal)=>{
                            setFilter(newVal);
                            setSelectedDocument(null);
                            setRowData([]);
                        }}
                    >
                        <FormControlLabel value="job-descriptions"
                        sx={{
                            background:filter === 'job-descriptions' ? "#EA723C" : undefined,
                            borderRadius:"22px",
                            color:filter === 'job-descriptions' ? "white" : undefined,
                            padding:"0.05rem 0.75rem 0 0",
                        }}
                        control={<Radio 
                            sx={{
                                '&.Mui-checked': {
                                    color: "white",
                                },
                            }}  
                        />} label="Job Descriptions" />
                        <FormControlLabel value="resumes"
                        sx={{
                            background:filter === 'resumes' ? '#EA723C' : undefined,
                            borderRadius:"22px",
                            color:filter === 'resumes' ? "white" : undefined,
                            padding:"0.05rem 0.75rem 0 0",
                        }}
                        control={<Radio 
                        sx={{
                            '&.Mui-checked': {
                                color: "white",
                            },
                        }}
                        />} label="Resumes" />
                        <Fade in={isStale} mountOnEnter unmountOnExit>
                            {
                                isMobile ? 
                                <IconButton
                                    style={{
                                        position:"absolute",
                                        right:"calc(2% + 1rem)"
                                    }}
                                    onClick={handleUpdateDocuments}
                                >
                                    <AutorenewOutlined style={{
                                        color:"#EA723C"
                                    }} />
                                </IconButton>
                                :
                                <Button
                                    variant="contained"
                                    style={{
                                        padding:"0.8rem",
                                        borderRadius:"0.5rem",
                                        font:"inherit",
                                        background:"#EA723C",
                                        margin:"auto 0 auto auto",
                                        height:"2.3rem",
                                        textTransform:"none"
                                    }}
                                    startIcon={<AutorenewOutlined />}
                                    onClick={handleUpdateDocuments}
                                >
                                    Load new documents
                                </Button>
                            }
                        </Fade>
                    </RadioGroup>

                    
                    <CustomAutoComplete
                        disablePortal
                        id="combo-box-demo"
                        options={documents?.filter(x=>x.document_type === filter) || []}
                        getOptionKey={(document)=>document._id}
                        getOptionLabel={(document)=>document ? document.filename : ''}
                        sx={{ width: "100%", margin:"0.5rem 0.5rem"}}
                        defaultValue={null}
                        value={selectedDocument || null}
                        renderInput={(params) => <TextField {...params} label="Select Document" />}
                        onChange={handleChangeDocument}
                    />

                </div>

                <CustomGrid 
                    gridId={"rank-and-analyze-grid"}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    containerStyle={{ mobile:{
                        width: 'calc(2rem + 100%)', height:"100%", marginLeft:"-1rem", marginTop:"0rem"
                    }}}
                />
            </Paper>
        </div>
    )
}

export default RankAndAnalyze;