import useResponsiveView from "@hooks/useResponsiveView";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "@styles/agtable.css";
import { CustomGridPagination } from "..";
import { useState } from "react";

const CustomGrid = ({children, gridId, rowData, columnDefs, containerStyle={mobile:{}, desktop:{}}, ...params}) => {
    const isMobile = useResponsiveView();
    const [gridAPI, setGridAPI] = useState(null);

    return (
        <>
            <div key={gridId} id={gridId} className={"ag-theme-quartz" + (isMobile ? " mobile" : "")} style={isMobile ? { width: '100%', borderRadius:"16px", flexGrow:"1", margin:"1rem", overflow:"auto", ...containerStyle.mobile } : { width: 'calc(2rem + 100%)', height:"100%", marginLeft:"-1rem", ...containerStyle.desktop }}>
                {children}
                <AgGridReact 
                    getRowId={(params)=>params.data._id}
                    pagination={true} 
                    defaultColDef={{wrapHeaderText:true, minWidth:(isMobile ? 150 : 100), flex:3, resizable: false, sortable:false, cellStyle:{textAlign:"center"}, headerClass:"centered-header"}}
                    rowData={rowData} 
                    columnDefs={columnDefs}
                    rowHeight={"70px"}
                    suppressCellFocus
                    onGridReady={(params)=>{
                        setGridAPI(params.api);
                    }}
                    suppressPaginationPanel
                    paginationPageSize={100}
                    gridId={gridId}
                    {...params}
                />
            </div>
            <CustomGridPagination gridAPI={gridAPI} />
        </>
    )
}

export default CustomGrid;