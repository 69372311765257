import { FileUploadIcon, FileDeleteIcon } from "@icons/FilesIcons";
import { isSuperAdmin } from "@utils/accessControl";

const PermissionsRenderer = ({data:user, onUpdate=(user_id, update)=>{}})=>{
    return (
        <div
            style={{
                display:"flex"
            }}
        >
            <div
                style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    cursor:isSuperAdmin(user) ? "not-allowed" : "pointer",
                    pointerEvents:isSuperAdmin(user) ? "none" : undefined
                }}
                onClick={(event)=>{
                    onUpdate(user._id, {access_post:!user.access_post});
                }}
            >
                {
                user.access_post ? 
                    <FileUploadIcon color={isSuperAdmin(user) ? 'lightgray' :"#EA723C"} width={"15"} /> 
                    : 
                    <FileUploadIcon color={isSuperAdmin(user) ? 'lightgray' :"#6D6D6D"} width={"15"}/>
                }
                <p
                    style={{
                        color:isSuperAdmin(user) ? 'lightgray' :user.access_post ? "#EA723C" : "#6D6D6D",
                        marginLeft:"0.3rem",
                        fontWeight:500,
                        fontSize:"0.8rem"
                    }}
                >Upload</p>
            </div>

            <div
                style={{
                    marginLeft:"1.2rem",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    cursor:isSuperAdmin(user) ? "not-allowed" : "pointer",
                    pointerEvents:isSuperAdmin(user) ? "none" : undefined
                }}
                onClick={(event)=>{
                    onUpdate(user._id, {access_delete:!user.access_delete});
                }}
            >
                {
                user.access_delete ? 
                    <FileDeleteIcon color={isSuperAdmin(user) ? 'lightgray' :"#EA723C"} width={"15"} /> 
                    : 
                    <FileDeleteIcon color={isSuperAdmin(user) ? 'lightgray' :"#6D6D6D"} width={"15"}/>
                }
                <p
                    style={{
                        color:isSuperAdmin(user) ? 'lightgray' :user.access_delete ? "#EA723C" : "#6D6D6D",
                        marginLeft:"0.3rem",
                        fontWeight:500,
                        fontSize:"0.8rem"
                    }}
                >Delete</p>
            </div>
        </div>
    )
}

export default PermissionsRenderer;