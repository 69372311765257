import { IconButton, Tooltip } from "@mui/material";
import { EditUserIcon, DeleteIcon } from "@icons/ActionsIcons";
import { isSuperAdmin } from "@utils/accessControl";
import "./action-renderer.css";

const ActionsRenderer = ({data:user, onDelete=(user_id)=>{}, onEdit=(user_id)=>{}})=>{
    return (
        <>
            {
                isSuperAdmin(user) ? 
                <IconButton disabled>
                    <EditUserIcon color='lightgray'/> 
                </IconButton> :
                <Tooltip title="Edit" arrow>
                    <IconButton
                        className="action-btn"
                        onClick={(event)=>{
                            onEdit(user._id);
                        }}
                    >
                        <EditUserIcon className="action-svg-icon" />
                    </IconButton>
                </Tooltip>
            }

            {
                isSuperAdmin(user) ? 
                <IconButton disabled>
                    <DeleteIcon color='lightgray'/> 
                </IconButton> :
                <Tooltip title="Delete" arrow>
                    <IconButton
                        className="action-btn"
                        onClick={(event)=>{
                            onDelete(user._id);
                        }}
                    >
                        <DeleteIcon className="action-svg-icon" />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}

export default ActionsRenderer;