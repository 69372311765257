import { ForkLeft, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Paper } from "@mui/material";
import "./help-and-support.css";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { DialogForm } from "@components/index";
import { RAISE_A_TICKET } from "@constants/forms";
import { getFormJson } from "@utils/formUtils";
import { raiseTicket } from "@services/ticketsService";
import { useAuth } from "@contexts/AuthContext";
import { toast } from "react-toastify";

const FAQsData = [
    {
      question: "What is a Scan2hire and how does it work?",
      answer:
        "Our Scan2Hire software, powered by advanced resume parsing technology, automatically extracts essential details from resumes, such as contact information, work experience, and key skills. By using AI and machine learning, it organizes this data for a streamlined review, saving recruiters valuable time and effort.",
    },
    {
      question: "What formats does the Scan2hire support?",
      answer:
        "Scan2Hire’s resume parser is compatible with popular resume formats, including PDF, DOC, DOCX, and TXT, ensuring it can handle most standard submissions.",
    },
    {
      question: "How accurate is the resume parsing technology?",
      answer:
        "Our parser achieves over 95% accuracy in data extraction, minimizing errors and ensuring reliable candidate information.",
    },
    {
      question: "Can Scan2hire integrate with my existing systems?",
      answer:
        "Yes, Scan2Hire’s resume parser integrates effortlessly with popular ATS and HR software, fitting smoothly into your existing workflow for a seamless recruitment process.",
    },
    {
      question: "How does Scan2Hire improve the hiring process?",
      answer:
        "By automating resume screening, Scan2Hire accelerates your recruitment process, enhances data accuracy, and enables you to quickly identify top candidates.",
    },
    {
      question: "Is my data secure with Scan2hire?",
      answer:
        "Absolutely, Scan2Hire prioritizes data security with robust protocols, safeguarding your sensitive information and ensuring confidentiality.",
    },
    {
      question: "How can I get started with Scan2Hire?",
      answer:
        "Simply sign up for a free trial on our website, and we’ll guide you through a quick and easy setup process to start experiencing Scan2Hire’s benefits right away.",
    },
  ];

const FAQsItem = ({faq})=>{
    const [open, setOpen] = useState(false);
    
    return (
        <div className={`faqs-item`} onClick={()=>setOpen(prev=>!prev)}>
            <div className="faqs-question">
                {faq.question}
                <h3 className="faqs-button">{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h3>
            </div>
            <Collapse in={open} >
                <div className="faqs-bar"></div>
                <div className="faqs-answer">{faq.answer}</div>
            </Collapse>
        </div>
    )
}

const HelpAndSupport = ()=>{
    const [openTicketDialog, setOpenTicketDialog] = useState(false);

    const handleTicketSubmit = async (event)=>{
        event.preventDefault();
        const formData = new FormData(event.target);
        try{
            await raiseTicket(formData);
            toast.success("Ticket submitted", {closeOnClick:true});
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }finally{
            setOpenTicketDialog(false);
        }
    }

    return (
        <div className={isMobile ? "home-container" : "help-and-support-container"} style={ isMobile ? {height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"} : null}>
            <DialogForm 
                {...RAISE_A_TICKET}
                open={openTicketDialog}
                onClose={()=>setOpenTicketDialog(false)}
                onSubmit={handleTicketSubmit}
            />
            
            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:isMobile ? "100%" : "93%",
                    marginTop:isMobile ? null : "0.5rem",
                    padding:"0.5rem 1rem",
                    maxHeight:"100%",
                    borderRadius:isMobile ? "20px" : "32px",
                    display:"flex",
                    flexDirection:"column",
                    overflow:"hidden",
                    position:"relative",
                }}
            >
                <div className="help-and-support-header">
                    <h4
                        style={{
                            fontSize:"1.4rem",
                            width:"100%",
                            margin:"0",
                            color:"#EA723C",
                            boxSizing:"border-box"
                        }}
                    >Frequently asked questions</h4>
                    <p
                        style={{
                            fontSize:"1rem",
                            margin:"0.5rem 0rem",
                            fontStyle:"italic"
                        }}
                    >Questions you might ask about our products and services</p>    
                </div>
                <div
                    style={{
                        width:"100%",
                        display:"flex",
                        flexGrow:1,
                        flexDirection:"column",
                        alignItems:"center",
                        overflowY:"auto",
                        scrollbarGutter:"stable",
                    }}
                >
                    {
                        FAQsData.map((item, idx)=>(
                            <FAQsItem key={idx} faq={item} />
                        ))
                    }
                </div>

                <p
                    style={{
                        width:"100%",
                        height:"1px",
                        borderBottom:"2px solid lightgray",
                        margin:"1rem 0rem"
                    }}
                ></p>

                <div className="raise-ticket-section">
                    <div>
                        <h4 >Still need help?</h4>
                        <p >Submit a support ticket and our team will assist you.</p>
                    </div>
                    <button className="ticket-button" onClick={()=>setOpenTicketDialog(true)}>
                        Raise a Ticket
                    </button>
                </div>
            </Paper>
        </div>
    )
}

export default HelpAndSupport;
