const StatusRenderer = (params) => {
    const renderMap = {
        null : {
            text : "Pending",
            background : '#FFF1D6',
            color : '#B86F01'
        },
        true : {
            text : "Active",
            background : '#DBF6E5',
            color : '#1A925D'
        },
        false : {
            text : "Banned",
            background : '#FFE4DE',
            color : "#C4423D"
        }
    }
    return (
        <div className="centered-cell">
            <div
                style={{
                    width:"70%",
                    height:"45%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    background : renderMap[params.value].background,
                    color : renderMap[params.value].color,
                    borderRadius:"0.5rem",
                    fontSize:"1rem",
                    fontWeight:500
                }}
            >
                {renderMap[params.value].text}
            </div>
        </div>
    )
};

export default StatusRenderer;