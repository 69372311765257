export const pageErrors = {
    PAGE_NOT_FOUND : {
        errorCode : 404,
        errorTitle : "Page Not Found",
        errorDescription : "We couldn't find the page you're looking for. This might be due to an outdated link or a mistyped URL.",
        suggestion : "Please check the URL or return to the homepage.",
        homeButton : "Return to Homepage"
    },
    FORBIDDEN : {
        errorCode : 403,
        errorTitle : "Forbidden",
        errorDescription : "Access denied",
        suggestion : "Please check the URL or return to the homepage.",
        homeButton : "Return to Homepage"
    }
}