import { Switch } from "@mui/material";
import { isSuperAdmin } from "@utils/accessControl";

const AccessRenderer = ({data:user, onUpdate=(user_id, update)=>{}})=>{
    return (
        <Switch
            disabled={isSuperAdmin(user)}
            checked={user.verified === null ? false : user.verified} 
            style={{
                color: isSuperAdmin(user) ? 'lightgray' : '#EA723C'
            }}
            sx={{
                '& .MuiSwitch-thumb': {
                    padding:0.5,
                    width: 10,
                    height: 10,
                    backgroundColor: 'white',
                    border: isSuperAdmin(user) ? "lightgray" : "1px solid #EA723C" 
                },
                '& .MuiSwitch-track': {
                    backgroundColor: '#CDCDCD',
                },
                '& .Mui-checked+.MuiSwitch-track' : {
                    backgroundColor: "#EA723C"
                }
            }}  
            onClick={(event)=>{
                onUpdate(user._id, {verified:!user.verified});
            }}
        />
    )
}

export default AccessRenderer;