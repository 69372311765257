const GenerateIcon = ({color="black", ...params})=>{
    return (
        <svg fill={color} {...params} version="1.1" id="Layer_1" viewBox="0 0 32 32" width={32} height={32}>
        <path id="ideate_1_" d="M16,29.36c-7.083,0-12.897-5.542-13.333-12.518l-1.412,1.412l-0.509-0.51L3,15.491l2.254,2.254l-0.509,0.51
            l-1.354-1.354C3.855,23.452,9.333,28.64,16,28.64c5.891,0,10.949-3.998,12.302-9.723l0.701,0.166
            C27.573,25.134,22.227,29.36,16,29.36z M29,16.509l-2.255-2.255l0.51-0.509l1.354,1.354C28.145,8.548,22.667,3.36,16,3.36
            c-5.889,0-10.946,3.998-12.298,9.723L3,12.917C4.43,6.866,9.776,2.64,16,2.64c7.083,0,12.897,5.542,13.334,12.517l1.411-1.412
            l0.51,0.509L29,16.509z M17,24.36h-2v-0.72h2V24.36z M18,22.36h-4v-0.72h4V22.36z M18,20.36h-4c-0.199,0-0.36-0.161-0.36-0.36
            v-0.269c0-1.082-0.488-2.108-1.492-3.137c-1.295-1.328-2.008-2.817-2.008-4.194c0-3.123,2.684-5.76,5.86-5.76
            c3.177,0,5.86,2.638,5.86,5.76c0,1.377-0.714,2.866-2.009,4.194c-1.004,1.029-1.491,2.055-1.491,3.137V20
            C18.36,20.199,18.199,20.36,18,20.36z M14.359,19.64h3.282c0.026-1.244,0.581-2.406,1.695-3.548c0.674-0.69,1.804-2.088,1.804-3.691
            c0-2.732-2.354-5.041-5.14-5.041s-5.14,2.309-5.14,5.041c0,1.602,1.13,3,1.804,3.691C13.778,17.233,14.333,18.396,14.359,19.64z"/>
        <rect id="_Transparent_Rectangle" style={{fill:"none"}} width="32" height="32"/>
        </svg>
    )
}

export default GenerateIcon;