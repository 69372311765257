import useResponsiveView from "@hooks/useResponsiveView";
import { TableCell } from "@mui/material";

export const CustomTabelCell = ({sx={}, ...params})=>{
    const isMobile = useResponsiveView();
    return (
        <TableCell
            align="center"
            sx={{
                fontFamily:"Montserrat",
                fontSize:isMobile ? "0.8rem" : "1rem",
                padding:isMobile ? "1rem" : undefined,
                ...sx
            }}
            {...params}
        >
            {params.children}
        </TableCell>
    )
}

export const CustomHeadRow = ({column1, column2})=>{
    return (
        <div
                style={{
                width:"95%",
                borderRadius:"12px",
                fontFamily:"Montserrat",
                marginBottom:"1rem",
                height:"fit-content",
                minHeight : "2rem",
                display:"flex",
                alignItems:"center",
                padding:"0.5rem",
                fontWeight:"600",
                background:"#EA723C33"
            }}
        >
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column1}</div>
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column2}</div>
        </div>
    )
}

export const CustomRow = ({column1, column2, borderFlag=true})=>{
    return (
        <div
                style={{
                width:"90%",
                fontFamily:"Montserrat",
                height:"fit-content",
                minHeight : "1.5rem",
                display:"flex",
                alignItems:"center",
                padding:"0.75rem",
                borderBottom:borderFlag ? "1px solid #1E1E1E5A" : "none"
            }}
        >
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column1}</div>
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column2}</div>
        </div>
    )
}
