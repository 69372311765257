import { authAPI } from "./axiosIstances";

export const login = async (credentials) => {
    const response = await authAPI.post("/login", credentials);
    return response.data;
}

export const logout = async () => {
    await authAPI.get("/logout");
}

export const getUser = async () => {
    const response = await authAPI.get("/user");
    return response.data;
}