import styles from "./LoadingIndicator.module.css";
import { CircularProgress } from "@mui/material";

const LoadingIndicator = ()=>{
    return (
    <div className={styles.container}>
        <CircularProgress />
    </div>
)}

export default LoadingIndicator;