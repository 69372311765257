import PreviewIcon from "@icons/ActionsIcons/PreviewIcon";
import { IconButton, Tooltip } from "@mui/material";

const PreivewRenderer = ({data:document, onPreview=(document)=>{}})=>{
    return (
        <div>            
            <Tooltip title="Preview" arrow>
                <IconButton className="action-btn" onClick={()=>onPreview(document)}>
                    <PreviewIcon className="action-svg-icon" />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default PreivewRenderer;