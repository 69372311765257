const extractErrorMessage = (error)=>{
    if(error.response){
        if(error.response.status === 500){
            return "Server Error"
        }
        return error.response?.data?.message || "An unexpected error occurred";
    }else if(error.request) {
        return "No response from server";
    }else{
        return error.message || "An unexpected error occurred";
    }
}

export { extractErrorMessage };