import { Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import DocumentTableView from "./DocumentTableView";
import { CrossCircleIcon } from "@icons/DialogsIcons";
import useResponsiveView from "@hooks/useResponsiveView";
import { useEffect, useRef, useState } from "react";

import { getFile } from "@services/documentsService";
import { LoadingIndicator } from "..";
import { toast } from "react-toastify";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { VerbosityLevel } from "pdfjs-dist";
import { Link } from "react-router-dom";
import { Download, OpenInBrowser, OpenInBrowserOutlined, OpenInBrowserRounded, OpenInNew } from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const DocumentPreview = ({open=false, document={}, onClose=()=>{}})=>{
    const isMobile = useResponsiveView();
    const [tab, setTab] = useState('info');
    const [previewDocument, setPreviewDocument] = useState(null);
    const [cacheDocument, setCacheDocument] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const [numPages, setNumPages] = useState(0);
    const containerRef = useRef(null);

    useEffect(()=>{
        setLoading(true);   
        
        if(!open) return;
        
        if(!document){
            setPreviewDocument(null);
            return
        }

        const fetchDocument = async ()=>{
            if(cacheDocument){
                setPreviewDocument(
                    URL.createObjectURL(cacheDocument)
                );
                console.log(URL.createObjectURL(cacheDocument))
                setTimeout(()=>{
                    setLoading(false);
                }, 100)
                return;
            }

            try{
                const fileBlob = await getFile(document._id);
                let mimetype = document.filename.endsWith(".pdf") ? 'application/pdf' : 'application/octet-stream';
                const url = URL.createObjectURL(fileBlob, {type:mimetype});
                setCacheDocument(fileBlob);
                setPreviewDocument(url);
            }catch(error){
                toast.error(error.message);
            }finally{
                setLoading(false);
            }
        }

        fetchDocument();
    }, [open, document])

    const handleClose = () => {
        onClose();
        setPreviewDocument(null);
        setCacheDocument(null);
        URL.revokeObjectURL(previewDocument);
    }
    
    return (
        <Dialog fullWidth open={open || false} onClose={handleClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:isMobile ? "0" : "1rem", height:"80%", maxHeight:"550px", background:"white"},
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    position:"relative"
                }}
            >
                <div
                    style={{
                        color:"#1E1E1E",
                        fontFamily:"Montserrat",
                        fontWeight:"700"
                    }}
                >
                    Preview
                </div>
                <Tabs value={tab} onChange={(event, newVal)=>setTab(newVal)} aria-label="basic tabs example"
                    style={{
                        width:"100%"
                    }}
                    sx={{
                        '& .MuiTabs-indicator': {
                            display: 'flex',
                            backgroundColor: '#EA723C',
                            height:"3px",
                        },
                    }}
                    centered
                >
                    <Tab sx={{fontFamily:"Montserrat", fontSize:"1.1rem", borderBottom:"2px solid #00000080", textTransform:"none", color:"#1E1E1E80", fontWeight:"600", width:"45%", 
                        '&.Mui-selected': {
                            color:"#EA723C",
                            backgroundColor: 'transparent'
                        }
                    }} 
                    label="Info" value={'info'} />
                    <Tab sx={{fontFamily:"Montserrat", fontSize:"1.1rem", borderBottom:"2px solid #00000080", textTransform:"none", color:"#1E1E1E80", fontWeight:"600", width:"45%", 
                        '&.Mui-selected': {
                            color : "#EA723C",
                            backgroundColor: 'transparent'
                        }
                    }} label="Document" value={'file'} />
                </Tabs>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                    <CrossCircleIcon color="#1E1E1E" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    scrollbarGutter :"stable both-edges"
                }}
                ref={containerRef}
            >
                
                {
                    tab === 'file' ?
                    (
                        (loading || !previewDocument) ? <LoadingIndicator /> :
                        <div style={{ width: "95%", paddingTop:"2rem", height: "100%", display:"flex", position:"relative", justifyContent:"center" }}>
                            <div
                                style={{
                                    width:"100%",
                                    height:"1.5rem",
                                    top:"0",
                                    right:"0",
                                    position:"absolute",
                                    zIndex:51,
                                    display: "flex", 
                                    gap: "0rem", 
                                    alignItems:"center",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Tooltip title="Open In New" arrow>
                                    <IconButton
                                        component="a"
                                        href={previewDocument}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#EA723C" }}
                                        >
                                        <OpenInNew style={{color:"gray"}} fontSize="medium"/>
                                    </IconButton>
                                </Tooltip>
                                
                                <Tooltip title="Download" arrow>
                                    <IconButton onClick={()=>{
                                        const a = window.document.createElement("a");
                                        a.href = previewDocument;
                                        a.download = document?.filename || "download";
                                        a.click();
                                    }}>
                                        <Download style={{color:"gray"}} fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            {
                                document?.filename.endsWith(".pdf") ?
                                <Document file={previewDocument} onLoadSuccess={({numPages})=>setNumPages(numPages)} style={{width:"100%", height:"100%"}} noData={<div>No document available</div>}>
                                        {
                                            Array.from({length : numPages}).map((_, idx)=>{
                                                return <Page key={idx} loading={()=>{}} width={containerRef.current?.offsetWidth * 0.90} scale={1} pageNumber={idx+1} />
                                            })
                                        }
                                </Document>
                                :
                                <object
                                    data={previewDocument}
                                    width="95%"
                                    height="100%"
                                />
                            }
                        </div>
                    )
                    : <DocumentTableView document={document} />
                }
            </DialogContent>
        </Dialog>
    )
}

export default DocumentPreview;