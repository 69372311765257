import "./fill-button.css";

const FillButton = ({value, className, disabled, startIcon="", ...params})=>{
    return (
        <button className={`${className} fill-button-container ${disabled ? "disabled" : null}`} {...params}>
            <div className="content">
                <p>{startIcon}</p>
                <p style={{marginLeft:"0.5rem"}}>{value}</p>
            </div>
            <div className={`fill-button-liquid`} />
        </button>
    )
}

export default FillButton;