const NUMBERS = '0123456789'
const ALPHABETS = 'abcdefghijklmnopqrstuvwxyz' 

export const generatePassword = (length=12) => {
    const charSet = NUMBERS + ALPHABETS + ALPHABETS.toUpperCase();
    if(crypto.getRandomValues){
        return Array.from(crypto.getRandomValues(new Uint32Array(length)))
            .map((num)=>charSet[num % charSet.length])
            .join('');
    }
    
    // fallback to random
    let password = "";
    for(let i=0; i<length; i++){
        password += charSet[Math.floor(Math.random() * charSet.length)]
    }
    return password;
}