import "./home.css";
import useResponsiveView from "@hooks/useResponsiveView";
import { Button, Paper } from "@mui/material";

const Home = ({onTour}) => {
    const isMobile = useResponsiveView();
    return (
        <>
            {
                isMobile ?
                // Mobile View
                <div
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        width:"100%"
                    }}
                >
                    <div
                        style={{
                            width:"100%",
                            height:"45svh",
                            borderRadius:"16px",
                            backgroundImage:"url(/video.jpg)",
                            backgroundSize:"auto 45svh",
                            backgroundPosition:"center",
                            backgroundRepeat:"no-repeat"
                        }}
                    />
                    <div
                        style={{
                            marginLeft:"1rem",
                            fontSize:"2.5rem",
                            fontWeight:"800",
                            color:"#EA723C",
                            marginTop:"1rem",
                            textAlign:"center"
                        }}
                    >Welcome to Scan2Hire!</div>
                    <p
                        style={{
                            fontSize:"1rem",
                            fontWeight:"500",
                            margin:"0.5rem 1rem 1.5rem 1rem",
                            fontStyle:"italic",
                            textAlign:"center"
                        }}
                    >"Streamline Your Hiring Process: <br /> Introducing Scan2Hire"</p>

                    <Button 
                        variant="contained" 
                        style={{
                            textTransform:'none', 
                            color:"white",
                            borderRadius:"12px",
                            fontWeight:"600",
                            fontFamily:"montserrat", 
                            fontSize:"0.9rem",
                            background:"#EA723C",
                        }}
                        onClick={()=>onTour()}
                    >Take a Tour</Button>
                </div>
                
                :
                
                // Desktop View
                <div className="home-container">
                    <Paper
                        elevation={0}
                        sx={{
                            width:"93%",
                            marginTop:"0.5rem",
                            padding:"0.5rem 1rem",
                            borderRadius:"32px",
                            background:"#EA723C1A",
                        }}
                    >
                        <div
                            style={{
                                marginLeft:"1rem",
                                fontSize:"1.5rem",
                                fontWeight:"800",
                                color:"#EA723C",
                                marginTop:"1rem",
                            }}
                        >Welcome to Scan2Hire!</div>
                        <p
                            style={{
                                fontSize:"0.9rem",
                                fontWeight:"500",
                                margin:"0.5rem 1rem 1.5rem 1rem",
                                fontStyle:"italic"
                            }}
                        >"Streamline Your Hiring Process: Introducing Scan2Hire"</p>
                    </Paper>

                    <Paper
                        elevation={3}
                        sx={{
                            width:"93%",
                            marginTop:"1.5rem",
                            padding:"0.5rem 1rem",
                            height:"100%",
                            borderRadius:"32px",
                            backgroundImage:"url(/video.jpg)",
                            backgroundSize:"120% auto",
                            backgroundPosition:"center",
                            backgroundRepeat:"no-repeat"
                        }}
                    />
                </div>
            } 
        </>
    )
}

export default Home;