import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';

import styles from "./SiteTour.module.css";
import Tour from 'reactour';
import { CrossCircleIcon } from '@icons/DialogsIcons';

const TourContent = ({title, content, onClose}) => {
    return (
        <>
            <div>
                <div
                    style={{
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        maxWidth:"30rem"
                    }}
                >
                    <h4
                        style={{
                            margin:"1rem 0rem",
                            width:"100%"
                        }}
                    >{title}</h4>
                    <IconButton
                        style={{
                            right:"0",
                            position:"absolute",
                            color:"#909090",
                            margin:"0",
                            padding:"0"
                        }}
                        onClick={onClose}
                    ><CrossCircleIcon color="black" height="25" width="25" /></IconButton>        
                </div>
                {content}
            </div>
        </>
    )
}

const createStep = (selector, title, content, onClose, actionCustom) => {
    return {
        selector : selector,
        content : <TourContent title={title} content={content} onClose={onClose} />,
        actionCustom : actionCustom
    }
}

const SiteTour = ({tourSteps, onEnd}) => {
    const [open, setOpen] = useState(true);
    const [index, setIndex] = useState(-1);
    const [clickList, setClickList] = useState([]);

    const handleClose = () => {
        setOpen(false);
        onEnd();
    };

    const steps = tourSteps.map((item)=>createStep(item.selector, item.title, item.content, handleClose, item.actionCustom));    

    useEffect(()=>{
        setOpen(true);
        handleNextStep();
    }, [])

    const handleNextStep = () => {
        const newIndex = Math.min(index+1, steps.length-1);
        setIndex(newIndex);

        const step = steps[newIndex]
        const element = document.querySelector(steps[newIndex].selector);

        if(step?.actionCustom === 'click'){
            element.click();
            setClickList(prev=>[...prev, element]);
        }else{
            setClickList(prev=>[...prev, prev[prev.length-1]]);
        }
        
        if(index + 1 === steps.length){
            handleClose();
        }

    }

    const handlePrevStep = () => {
        const newIndex = Math.max(index-1, 0);
        setIndex(newIndex);
        clickList[newIndex].click();
    }

    return (
    <>
        <Tour
            steps={steps}
            isOpen={open}
            onRequestClose={handleClose}
            goToStep={index}
            nextStep={handleNextStep}
            prevStep={handlePrevStep}
            showCloseButton={false}
            accentColor={"#EA723C"}
            className={styles.siteTourHelper}
            maskSpace="0"
            rounded={"0px"}
        />

    </>
    )
}

export default SiteTour;