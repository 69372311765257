import { Link } from "react-router-dom";
import "./error-page.css";
import { pageErrors } from "@constants/errorPageInfo";

const ErrorPage = ({error=pageErrors.PAGE_NOT_FOUND})=>{
    return (
        <div className="container" >
            <div className="box">
                <div className="error-message">
                    <h1 className="error-code">{error.errorCode}</h1>
                    <h2 className="error-title">{error.errorTitle}</h2>
                    <p className="error-description">{error.errorDescription}</p>
                    <p className="suggestion">{error.suggestion}</p>
                    <Link to={"/"} className="home-button">{error.homeButton}</Link>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;